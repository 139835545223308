import React from 'react';

const ReservationContext = React.createContext({
    vehicles: [],
    contactInfo: [],
    fromDate: '',
    toDate: '',
    totalAmount: 0,
    addVehicle: (vehicle) => { },
    setDates: (dates) => { },
    removeAllVehicles: () => { }
});

export default ReservationContext;