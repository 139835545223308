import React, { useContext, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import './AvailableScooters.css';
import ReservationContext from '../store/reservation-context';


function DateForm(props) {
    const reservationCtx = useContext(ReservationContext);
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');
    const [errorMessage, setErrorMessage] = useState('Date input should not be empty!');

    const fromChangeHandler = (event) => {
        setFrom(event.target.value);
        console.log(event.target.value);
        let today = new Date(event.target.value);

        let date = today.toISOString().slice(0, 16);
        console.log(date);

        // add a day
        let toDate = new Date(date);
        toDate.setDate(today.getDate() + 1);
        setTo(toDate.toISOString().slice(0, 16));

    }

    const toChangeHandler = (event) => {
        setTo(event.target.value);

    }

    const submitHandler = (event) => {
        event.preventDefault();
        // if (new Date(to) <= new Date(from)) {
        //     setErrorMessage("Return date should be greater then pickup date!")
        //     return;
        // }
        const diffTime = Math.abs(new Date(to) - new Date(from));
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        reservationCtx.setDates({
            fromDate: from,
            toDate: to
        });


        props.onSelectDate(from, to);
    }





    return (<Form onSubmit={submitHandler}>
        <Row className="dateForm">

            <Form.Group as={Col}>
                <Form.Label>PickUp date and time:</Form.Label>
                <Form.Control required
                    id="inlineFormInputGroupUsername"
                    placeholder=""
                    type="datetime-local"
                    value={from}
                    min={new Date().toISOString().slice(0, -8)}
                    onChange={fromChangeHandler}
                />
                <Form.Control.Feedback type="invalid">
                    Please provide a valid PickUp date.
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col}>
                <Form.Label>Return date and time:</Form.Label>
                <Form.Control required
                    id="inlineFormInputGroupUsername"
                    placeholder=""
                    type="datetime-local"
                    value={to}
                    onChange={toChangeHandler}
                />
            </Form.Group>
            <Form.Control.Feedback type="invalid">
                Please provide a valid Return date.
            </Form.Control.Feedback>



            <Form.Group as={Col}>
                <Form.Label className='labelHidden'>Check button</Form.Label>
                <div className='buttonPading'>
                    <Button variant="warning" type="submit">Check available scooters!</Button>
                </div>
            </Form.Group>
        </Row>
    </Form>
    );
}






export default DateForm;