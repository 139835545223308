import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import React, { useState, useContext } from 'react';
import Container from 'react-bootstrap/esm/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import "./ContactInfo.css";
import axios from "axios";
import ReservationContext from '../store/reservation-context';


function ContactInfo(props) {

    const [number, setNumber] = useState("");
    const [country, setCountry] = useState("us");
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [email, setEmail] = useState("");
    const [halmets, setHalmets] = useState(props.minNumberOfHalmets);
    const [location, setLocation] = useState("Ul. Silvija Strahimira Kranjcevica 6, Novalja");
    const [message, setMessage] = useState("");
    const [validated, setValidated] = useState(false);

    const reservationCtx = useContext(ReservationContext);


    const locations = [{
        locationAddress: "Ul. Silvija Strahimira Kranjcevica 6, Novalja"
    }];


    function nameChangeHandler(event) {
        setName(event.target.value);
    }

    function surnameChangeHandler(event) {
        setSurname(event.target.value);
    }

    function emailChangeHandler(event) {
        setEmail(event.target.value);
    }

    function halmetsChangeHandler(event) {
        setHalmets(event.target.value);
    }

    function locationChangeHandler(event) {
        setLocation(event.target.value);
    }

    function messageChangeHandler(event) {
        setMessage(event.target.value);
    }

    function phoneChangeHandler(event, data) {
        setNumber(event);
        setCountry(data.name);

    }


    const updateReservation = async (contactInfo) => {


        const urlParams = new URLSearchParams(contactInfo);
        console.log(urlParams, "URL PARAMS")
        const response = await axios.get('https://testna.gigalixirapp.com/booking/update?' + urlParams,

            {
                "Content-Type": "application/json",
                "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
                "Access-Control-Allow-Headers": "Content-Type",
                "Access-Control-Allow-Origin": "*"
            }
        ).then((response) => console.log(response.data)
        ).catch((error) => { console.log(error) });
    }

    function isValidHandler() {


    }
    function onSubmitHandler(event) {
        console.log(country);

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
        if (form.checkValidity() === true) {
            event.preventDefault();

            const contactInfo = {
                id: localStorage.getItem("reservation_id"),
                firstName: name,
                lastName: surname,
                email: email,
                phone: number,
                country: country,
                halmets: halmets,
                location: location,
                message: message
            }
            console.log(contactInfo, "CONTACT INFO");
            updateReservation(contactInfo);
            props.onSecondSubmit();
        }
    }

    return (
        <Container className="paddingContainer">
            <Form onSubmit={onSubmitHandler} noValidate validated={validated}>
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridName">
                        <Form.Label>First name</Form.Label>
                        <Form.Control type="name" placeholder="Name" value={name} onChange={nameChangeHandler} required />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid First Name.
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridLastName">
                        <Form.Label>Last name</Form.Label>
                        <Form.Control type="last name" placeholder="Last name" value={surname} onChange={surnameChangeHandler} required />
                    </Form.Group>
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid Last Name.
                    </Form.Control.Feedback>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" placeholder="Enter email" value={email} onChange={emailChangeHandler} required />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid email.
                        </Form.Control.Feedback>
                    </Form.Group>

                </Row>
                <Row className="mb-3" >
                    <Form.Label className='left-aligned'>Phone number</Form.Label>
                    <PhoneInput

                        country={country}
                        value={number}
                        onChange={phoneChangeHandler}
                        isValid={isValidHandler}
                    />
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid email.
                    </Form.Control.Feedback>
                </Row>

                <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridCity">
                        <Form.Label>Number of halmets:</Form.Label>
                        <Form.Control type="number" min={props.minNumberOfHalmets} max={2 * props.minNumberOfHalmets} value={halmets} onChange={halmetsChangeHandler} required />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid number of halmets.
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridState">
                        <Form.Label>PickUp location:</Form.Label>
                        <Form.Select defaultValue="Choose..." value={location} onChange={locationChangeHandler}>
                            {locations.map((location) => (<option>{location.locationAddress}</option>))}

                        </Form.Select>
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Additional message:</Form.Label>
                        <Form.Control as="textarea" rows={4} value={message} onChange={messageChangeHandler} />
                    </Form.Group>
                </Row >
                <div className="right-aligned">
                    <Button variant="outline-warning" type="submit">
                        Submit
                    </Button>
                </div>
            </Form>
        </Container>
    );
}

export default ContactInfo;