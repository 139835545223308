import Card from 'react-bootstrap/Card';
import React, { useContext, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import * as Icon from 'react-bootstrap-icons';
import Figure from 'react-bootstrap/Figure';
import QuantityForm from './QuantityForm';
import ReservationContext from '../store/reservation-context';


function ScooterCard(props) {
    const reservationCtx = useContext(ReservationContext);

    const diffTime = Math.abs(new Date(reservationCtx.toDate) - new Date(reservationCtx.fromDate));
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const addToReservationHandler = (oldQuantity, quantity) => {
        reservationCtx.addVehicle({
            id: props.id,
            count: quantity
        });
        props.onQuantityChange(props.price * oldQuantity * diffDays, props.price * quantity * diffDays);

    };

    return (

        <Card>
            <Card.Header>{props.name}</Card.Header>
            <Card.Body >
                <Card.Text>
                    <Row>
                        <Col sm={4} className="center-aligned">
                            <div >
                                <Figure >
                                    <Figure.Image src={props.imgLocation} /></Figure>
                            </div>
                        </Col>
                        <Col sm={5} className="my-1" >
                            <p><Icon.EmojiSmile /> {props.feature1}</p>
                            <p><Icon.EmojiSmile /> {props.feature2}</p>
                            <p><Icon.EmojiSmile /> {props.feature3}</p>
                            <p>Free: {props.free}</p>
                        </Col>
                        <Col sm={3} className="right-aligned" >
                            <p>Price per day:</p>
                            <Button variant="warning">€{props.price.toFixed(2)}</Button></Col>
                    </Row>
                </Card.Text>

            </Card.Body>
            <Card.Footer className="right-aligned">
                <QuantityForm onAddToReservation={addToReservationHandler} price={props.price} numberOfDays={diffDays}></QuantityForm>
            </Card.Footer>
        </Card>

    );
}

export default ScooterCard;