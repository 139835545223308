import ReservationContext from "./reservation-context";
import React from "react";
import { useReducer } from "react";


const defaultReservationState = {
    vehicles: [],
    contactInfo: [],
    totalAmount: 0,
    fromDate: '',
    toDate: ''
}
const reservationReducer = (state, action) => {

    if (action.type == "ADD") {

        const updatedTotalAmount = state.totalAmount + 10;

        const existingReservationVehicleIndex = state.vehicles.findIndex(
            (vehicle) => vehicle.id === action.vehicle.id
        );
        const existingReservationVehicle = state.vehicles[existingReservationVehicleIndex];
        let updatedVehicles;

        if (existingReservationVehicle) {

            if (action.vehicle.count === 0) {
                updatedVehicles = state.vehicles.filter(vehicle => vehicle.id !== action.vehicle.id);
            } else {
                const updatedVehicle = {
                    ...existingReservationVehicle,
                    count: action.vehicle.count,
                };
                updatedVehicles = [...state.vehicles];
                updatedVehicles[existingReservationVehicleIndex] = updatedVehicle;
            }
        } else {

            updatedVehicles = state.vehicles.concat(action.vehicle);

        }
        return {
            ...state,
            vehicles: updatedVehicles,
            totalAmount: updatedTotalAmount,
        };
    }


    if (action.type == "SET_DATES") {



        const updatedFromDate = action.dates.fromDate;
        const updatedToDate = action.dates.toDate;

        return {
            ...state,
            fromDate: updatedFromDate,
            toDate: updatedToDate
        };
    }

    if (action.type == "REMOVE_ALL") {


        return {
            ...state,
            vehicles: [],
            totalAmount: 0,
        };
    }
    return defaultReservationState;



};

const ReservationProvider = (props) => {
    const [reservationState, dispatchReservationAction] = useReducer(reservationReducer, defaultReservationState);
    const addVehicleToReservationHandler = (vehicle) => {
        dispatchReservationAction({ type: "ADD", vehicle: vehicle })
    };
    const setDatesHandler = (dates) => {
        dispatchReservationAction({ type: "SET_DATES", dates: dates })
    };
    const removeVehiclesHandler = () => {
        dispatchReservationAction({ type: "REMOVE_ALL" })
    };
    const reservationContext = {
        vehicles: reservationState.vehicles,
        contactInfo: [],
        fromDate: reservationState.fromDate,
        toDate: reservationState.toDate,
        totalAmount: reservationState.totalAmount,
        addVehicle: addVehicleToReservationHandler,
        setDates: setDatesHandler,
        removeAllVehicles: removeVehiclesHandler

    };
    return <ReservationContext.Provider value={reservationContext}>
        {props.children}
    </ReservationContext.Provider>
};

export default ReservationProvider;