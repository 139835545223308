import React, { useState, useContext, Fragment } from 'react';
import DateForm from './DateForm';
import ScooterCard from './ScooterCard';
import Container from 'react-bootstrap/Container';
import './AvailableScooters.css';
import Button from 'react-bootstrap/esm/Button';
import Form from 'react-bootstrap/Form';
import ReservationContext from '../store/reservation-context';
import axios from "axios";
import Subtotal from './SubTotal';
import Carousel from 'react-bootstrap/Carousel';

function AvailableScooters(props) {

    const [scooters, setScooters] = useState([]);
    const [subTotal, setSubtotal] = useState(0);
    const [showSubTotal, setShowSubTotal] = useState(false);
    const [from, setFrom] = useState("");
    const [to, setTo] = useState("");
    const [numberOfDays, setNumberOfDays] = useState(0);



    const reservationCtx = useContext(ReservationContext);

    function bookNowHandler(event) {
        event.preventDefault();
        console.log(props.path);

        const reservation = {
            vehicles: JSON.stringify(reservationCtx.vehicles),
            fromDate: from,
            toDate: to,
            tag: props.path
        };
        addReservation(reservation);
        if (localStorage.getItem("reservation_id")) {
            localStorage.removeItem("reservation_id");
        }
        localStorage.setItem("reservation_id", "id");

        let vehiclesQuantity = 0;
        for (let vehicle of reservationCtx.vehicles) {
            vehiclesQuantity += vehicle.count;
        }
        props.onFirstSubmit(vehiclesQuantity);
    }

    function quantityChangeHandler(oldScooterTotal, scooterTotal) {

        const updatedTotal = subTotal - oldScooterTotal + scooterTotal;

        setSubtotal(updatedTotal);

        const diffTime = Math.abs(new Date(reservationCtx.toDate) - new Date(reservationCtx.fromDate));
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        setNumberOfDays(diffDays);




    }

    const getAvailableVecihles = async (from, to) => {


        const dates = {
            fromDate: from,
            toDate: to,
        };

        const urlParams = new URLSearchParams(dates);
        axios.post("https://testna.gigalixirapp.com/booking/test", { karlo: "karlo" }, {
            headers: {
                "Content-Type": "application/json",

            }
        })
        const response = await axios.get(
            "https://testna.gigalixirapp.com/vehicles/get?" + urlParams,
            {
                "Content-Type": "application/json",
            }
        ).then((response) => {

            console.log(response.data);


            let loadedScooters = [];
            for (const key in response.data.body) {
                console.log(response.data.body[key]);
                loadedScooters.push({
                    id: response.data.body[key].id,
                    name: response.data.body[key].name,
                    imgLocation: response.data.body[key].image_location,
                    pricePerDay: response.data.body[key].price_per_day,
                    feature1: "500cm3",
                    feature2: "For " + response.data.body[key].no_persons + " persons",
                    feature3: "protective halmets included",
                    free: response.data.body[key].max

                })

            }


            setScooters(loadedScooters);


        })
            .catch((error) => { console.log(error) });




    }


    const addReservation = async (reservation) => {

        const urlParams = new URLSearchParams(reservation);

        const response = await axios.get('https://testna.gigalixirapp.com/booking/create?' + urlParams,

            {
                "Content-Type": "application/json",
            }
        ).then((response) => {
            console.log(response.data)
            localStorage.setItem("reservation_id", response.data.id);
        }
        ).catch((error) => { console.log(error) });
    }



    const selectDateHandler = (selectedFrom, selectedTo) => {

        reservationCtx.removeAllVehicles();
        setScooters([]);
        setSubtotal(0);
        const fromDate = new Date(selectedFrom);

        fromDate.setSeconds(0);
        fromDate.setMilliseconds(0);

        let fromDateISO = fromDate.toISOString();
        fromDateISO = fromDateISO.slice(0, -5);

        fromDateISO += ".000000Z";

        console.log(fromDateISO);

        setFrom(fromDateISO);

        const toDate = new Date(selectedTo);

        fromDate.setSeconds(0);
        fromDate.setMilliseconds(0);

        let toDateISO = toDate.toISOString();
        toDateISO = toDateISO.slice(0, -5);

        toDateISO += ".000000Z";

        console.log(toDateISO);
        setTo(toDateISO);


        setShowSubTotal(true);
        getAvailableVecihles(fromDateISO, toDateISO);
        // setScooters([
        //     {
        //         id: "9f4074d2-8343-40a6-884b-020b7b5a7ed6",
        //         name: "SCOOTER PIAGGIO BEVERLY 500",
        //         imgLocation: "./scootersImages/SkuterBocno-150x150.png",
        //         pricePerDay: 30.00,
        //         feature1: "500cm3",
        //         feature2: "2 persons",
        //         feature3: "protective halmets included",
        //         free: 20


        //     }

        // ]);


    }


    return (
        <Container className="paddingContainer">
            <div className="paddingContainer">
                <Carousel slide={false}>
                    <Carousel.Item>
                        <div style={{ display: 'flex', width: '50%' }}>
                            <img
                                className="d-block w-100"
                                src="./././scootersImages/white.jpg"
                                alt="First slide"
                            />

                            <img
                                className="d-block w-100"
                                src="./././scootersImages/bg-hero-02.jpg"
                                alt="First slide"
                            />
                        </div>
                        <Carousel.Caption >
                            <div className='carouselCaption'>
                                <DateForm onSelectDate={selectDateHandler}></DateForm>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>

            </div>
            <div className='mobileForm'>
                <DateForm onSelectDate={selectDateHandler}></DateForm>
            </div>

            <Form onSubmit={bookNowHandler}>
                <div className="paddingContainer">


                    {scooters.map(scooter => {
                        return <div className='paddingContainer' >
                            <ScooterCard
                                id={scooter.id}
                                name={scooter.name}
                                imgLocation={scooter.imgLocation}
                                feature1={scooter.feature1}
                                feature2={scooter.feature2}
                                feature3={scooter.feature3}
                                free={scooter.free}
                                price={scooter.pricePerDay}
                                onQuantityChange={quantityChangeHandler}
                            ></ScooterCard>
                        </div>
                    })}
                </div >

                {showSubTotal && <Fragment>
                    <Subtotal subTotal={subTotal} days={numberOfDays} />
                    <div className="right-aligned">
                        <Button variant="outline-warning" type="submit">Book now!</Button>
                    </div></Fragment>}
            </Form>

        </Container >
    );
}






export default AvailableScooters;