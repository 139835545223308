import React, { Fragment, useState, useContext } from "react";
import Alert from 'react-bootstrap/Alert';

function Subtotal(props) {


    return <div className="right-aligned">
        <hr />

        <Alert variant="light">Subtotal: € {props.subTotal.toFixed(2)}</Alert>
        {props.days >= 3 && <Alert variant="warning">3+ days reservation 10% discount: -€ {(0.1 * props.subTotal).toFixed(2)}</Alert>}
        <p >*** pay reservation now and the rest on pick-up location </p>
    </div>
};


export default Subtotal;