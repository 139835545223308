import './App.css';
import React from 'react';
import Steper from './components/Steper';
import ReservationProvider from './store/ReservationProvider';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

const router = createBrowserRouter([
  {
    path: '/', element: <ReservationProvider>
      <div className="App">
        <Steper path='/'></Steper>
      </div>
    </ReservationProvider>
  },

  {
    path: '/agency', element: <ReservationProvider>
      <div className="App">
        <Steper path='/agency'></Steper>
      </div>
    </ReservationProvider>
  },
  {}
])

function App() {
  return (<RouterProvider router={router} />

  );
}

export default App;
