import React, { Fragment, useRef, useState } from "react";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Badge from 'react-bootstrap/Badge';
import './QuantityForm.css';

function QuantityForm(props) {
    const quantityRef = useRef(0);
    const [quantity, setQuantity] = useState(0);


    const onChangeHandler = (event) => {

        const oldQuantity = quantity;
        const enteredQuantity = quantityRef.current.value;
        const enteredNumberQuantity = + enteredQuantity;
        setQuantity(quantityRef.current.value);

        props.onAddToReservation(oldQuantity, enteredNumberQuantity);

    }
    let total = 0;
    if (quantityRef.current.value) {
        total = quantityRef.current.value * props.numberOfDays * props.price;
    }
    return (
        <Fragment>
            <Row className="right-aligned">
                <Col sm={7} className="my-1">
                    <div className="rightContent">
                        <InputGroup style={{ width: '50%' }}>
                            <InputGroup.Text>Quantity: </InputGroup.Text>
                            <input className="form-control"
                                ref={quantityRef}
                                placeholder="0"
                                type="number"
                                min="0"
                                onChange={onChangeHandler}
                            />
                        </InputGroup>
                    </div>
                </Col>
                <Col sm={5} className="my-1" style={{ textAlign: 'right' }}>
                    <div className="rightContent">
                        <label>Charge: </label>
                        <Row>
                            <Button variant="outline-warning">
                                {props.numberOfDays} days <Badge bg="secondary" style={{ marginLeft: '5rem' }}>€{(total).toFixed(2)}</Badge>

                            </Button>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Fragment>
    );
}






export default QuantityForm;