import React, { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import AvailableScooters from './AvailableScooters';
import ContactInfo from './ContactInfo';
import ReservationDetails from './ReservationDetails';
import axios from "axios";


function Steper(props) {

    const [key, setKey] = useState('available');
    const [contactDisabled, setContactDisabled] = useState(true);
    const [detailsDisabled, setDetailsDisabled] = useState(true);
    const [paymentDisabled, setPaymentDisabled] = useState(true);
    const [vehiclesQuantity, setVehiclesQuantity] = useState(0);

    function onFirstSubmitHandler(quantity) {
        setVehiclesQuantity(quantity);
        setKey('contact');
        setContactDisabled(false);
    }

    function onSecondSubmitHandler() {
        console.log("clicked2");
        setKey('details');
        setDetailsDisabled(false);




    }
    return (
        <Tabs
            defaultActiveKey="available"
            id="fill-tab-example"
            className="mb-3"
            fill
            activeKey={key}
            onSelect={(k) => setKey(k)}
        >
            <Tab eventKey="available" title="Available vehicles">
                <AvailableScooters onFirstSubmit={onFirstSubmitHandler} path={props.path} />
            </Tab>
            <Tab eventKey="contact" title="Contact info" disabled={contactDisabled}>
                {!contactDisabled && <ContactInfo onSecondSubmit={onSecondSubmitHandler} minNumberOfHalmets={vehiclesQuantity} />}
            </Tab>
            <Tab eventKey="details" title="Reservation details" disabled={detailsDisabled}>
                {!detailsDisabled && < ReservationDetails />}
            </Tab>
            <Tab eventKey="payment" title="Payment" disabled={paymentDisabled}>
            </Tab>
        </Tabs>
    );
}


export default Steper;