import React, { useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import './AvailableScooters.css';
import Button from 'react-bootstrap/esm/Button';
import ReservationContext from '../store/reservation-context';
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import axios from "axios";




const ReservationDetails = () => {

    const [number, setNumber] = useState("");
    const [country, setCountry] = useState("");
    const [name, setName] = useState("fnjhbdjhb");
    const [surname, setSurname] = useState("");
    const [email, setEmail] = useState("");
    const [halmets, setHalmets] = useState(0);
    const [location, setLocation] = useState("");
    const [message, setMessage] = useState("");

    const reservationCtx = useContext(ReservationContext);
    useEffect(() => {

        fetchReservationDetails();


    }, [fetchReservationDetails]);

    function fetchReservationDetails() {
        const storedReservationId = localStorage.getItem("reservation_id");

        console.log("stored" + storedReservationId);

        const urlParams = new URLSearchParams({ id: storedReservationId });
        const response = axios.get('https://testna.gigalixirapp.com/booking/get?' + urlParams,

            {
                "Content-Type": "application/json",
                "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
                "Access-Control-Allow-Headers": "Content-Type",
                "Access-Control-Allow-Origin": "*"
            }
        ).then((response) => {
            console.log(response.data);

            setName(response.data.first_name);
            setSurname(response.data.last_name);
            setEmail(response.data.email);
            setNumber(response.data.phone);
            setCountry(response.data.country);
            setLocation(response.data.location);
            setHalmets(response.data.halmets);
            setMessage(response.data.message);



        }
        ).catch((error) => { console.log(error) });
    }

    return (<Container className='paddingContainer'>
        <div className='paddingContainer'>

            <Card border="warning" style={{ width: '100%' }}>
                <Card.Header><p>Pickup date and time: {reservationCtx.fromDate}</p>
                    <p>Return date and time: {reservationCtx.toDate}</p></Card.Header>

                <Card.Body>
                    <Card.Title>Your contact info:</Card.Title>
                    <Card.Text>
                        <ListGroup variant="flush">
                            <ListGroup.Item>Name: {name} {surname}</ListGroup.Item>
                            <ListGroup.Item>Email: {email}</ListGroup.Item>
                            <ListGroup.Item>Phone number: {number}</ListGroup.Item>
                            <ListGroup.Item>Country: {country}</ListGroup.Item>
                            <ListGroup.Item>PickUp location: {location}</ListGroup.Item>
                            <ListGroup.Item>Number of halmets: {halmets}</ListGroup.Item>
                        </ListGroup>
                    </Card.Text>
                </Card.Body>
            </Card>
        </div>

        <div className='paddingContainer'>
            <Table striped className='center-aligned'>
                <thead>
                    <tr>
                        <th>Scooter</th>
                        <th>Scooter</th>
                        <th className='right-aligned'>Quantity</th>
                        <th className='right-aligned'>Charge</th>
                    </tr>
                </thead>
                <tbody>
                    {reservationCtx.vehicles.map((vehicle) => (
                        <tr>
                            <td>Picture</td>
                            <td>Name</td>
                            <td className='right-aligned'>{vehicle.quantity}</td>
                            <td className='right-aligned'> <Button variant="outline-warning">
                                2 days <Badge bg="secondary" style={{ marginLeft: '5rem' }}>€{(vehicle.price * vehicle.quantity).toFixed(2)}</Badge>

                            </Button></td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>

        <div className="right-aligned">
            <hr />
            <p>Subtotal: € </p>
            <p >*** pay reservation now and the rest on pick-up location </p>
            <Button variant="outline-warning" type="submit">Go to payment!</Button>
        </div>


    </Container>);
}






export default ReservationDetails;